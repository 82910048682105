footer.site-footer-wrapper {
  background-color: #324048;
  color: white;
  font-size: 12px;

  a {
    color: white;
    white-space: nowrap;
  }

  .socials--footer {
    display: flex;

    .socials__icon {
      width: 42px;
      height: 42px;
      margin-right: 15px;
      transition: opacity 0.3s;

      &:hover {
        opacity: 0.6;
      }
    }

    svg {
      .a-social {
        fill: none;
        stroke: #fff;
        opacity: 0.3;
      }

      .b-social {
        fill: #fff;
        stroke: none;
      }
    }
  }

  .footer__bottom-inner {
    padding: 24px 0;

    .footer__copyright {
      margin-top: 18px;
    }

    .footer__bank-logo {
      width: 250px;
    }

    .footer__sponsor-description {
      margin-bottom: 12px;
      margin-top: 24px;
    }

    @include large {
      display: flex;
      justify-content: space-between;

      .footer__bank-logo {
        float: right;
      }

      .footer__sponsor-description {
        text-align: right;
        margin-top: 0;
      }
    }
  }
}

.mobile-footer {
  button.primary {
    font-size: 13px;
  }
}
