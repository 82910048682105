.event-listing {
  @include mediumAndLarge {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 32px;
  }

  article.event {
    border: solid 2px $very-light-gray;
    transition: box-shadow 0.2s ease;

    &:hover {
      box-shadow: 0 0 40px rgba(0, 0, 0, 0.25);
    }

    @include mediumAndLarge {
      padding-bottom: 20px;

      &:last-child {
        border-bottom: solid 2px $very-light-gray;
        margin-bottom: 32px;
      }

      &:nth-child(odd) {
        grid-column: 1;
      }

      &:nth-child(even) {
        grid-column: 2;
      }

      figure {
        margin-right: 0;
      }
    }

    @include small {
      padding-bottom: 20px;

      &:last-child {
        border-bottom: solid 2px $very-light-gray;
        margin-bottom: 32px;
        padding-bottom: 32px;
      }
    }

    a.title-link {
      grid-column: 1;
    }

    a.title-link h2 {
      font-size: 22px;
      font-family: $primary-font;
      margin-bottom: 8px;
      margin-top: 20px;
    }

    .subtitle {
      @extend %subtitle;
      grid-column: 1;
    }

    .description {
      grid-column: 1;
    }

    figure {
      margin-right: 0;
      grid-column: 1;
    }

    div.button.primary {
      display: none;
    }

    > *:not(figure) {
      margin-left: 20px;
      margin-right: 20px;
    }
  }
}

section.cart {
  box-shadow: $shadow;
  border: solid 1px #e5e7e8;

  h2 {
    padding: 16px;
    border-bottom: 1px solid #e5e7e8;
    font-family: $secondary-font;
    font-size: 28px;
  }

  .content {
    .ticket-date {
      font-size: 14px;
      font-weight: normal;
    }

    .apply-promo-codes {
      .joint-input-button > .wrapper {
        .promo-number-field {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          padding: 14px 18px;
        }
      }
    }

    .add-promo-button {
      &:not([disabled]):hover,
      &:not([disabled]):focus {
        color: $dark-red;
      }
    }

    .ticket-wrapper .actions button.remove {
      margin: 1px 0 0 0;
    }
  }

  button.primary.purchase {
    font-size: 18px;
    padding: 20px 24px;

    &:hover,
    &:focus {
      color: white;
      background-color: $dark-red;
      border-color: $dark-red;
    }
  }
}

.content-detail-page {
  .description-wrapper {
    .subtitle {
      @extend %subtitle;
      margin-bottom: 24px;
      padding-bottom: 24px;
      font-size: 16px;
      border-bottom: $divider;

      @include mobileExperience {
        line-height: 25px;
      }
    }
  }

  .ticket-type-description {
    .title {
      font-size: 20px;
    }

    .subtitle {
      margin-top: 2px;
    }
  }

  &.membership .select-tickets .section-header {
    margin-top: 16px;
  }
}

.stepper {
  > button {
    border-radius: 5px;
  }
}

.selection {
  h2 {
    margin-bottom: 8px;
  }

  .tickets {
    margin-top: 24px;
  }

  &.quantity-first {
    .btn-find-tickets {
      padding: 18px 24px;
    }

    section.quantity {
      margin-top: 8px;
    }

    button.change {
      &:hover,
      &:focus {
        color: $dark-red;
      }
    }

    .subtitle {
      font-size: 16px;
    }
  }
}

.select-tickets {
  margin-bottom: 0;

  .ticket-type,
  .ticket-type-headers {
    &.fixed-price {
      .price-wrapper {
        font-size: 20px;
      }
    }
  }

  .ticket-type {
    margin-bottom: 28px;
  }
}

.checkout-route {
  .purchaser-details {
    > h2 {
      margin-top: 0;
    }
  }

  .purchaser-details,
  .upsell,
  .payment,
  .terms-conditions {
    &:not(:last-child) {
      border-bottom: $divider;
      padding-bottom: 32px;
      margin-bottom: 32px;

      @include large {
        padding-bottom: 40px;
        margin-bottom: 40px;
      }
    }
  }
}

input[type='checkbox'] {
  background-color: $input-background-color;
  border: $divider;
}

.complete {
  .main {
    border-radius: 5px;
    box-shadow: $shadow;
    border: solid 1px #e5e7e8;
    padding: 0 24px 50px;

    h1 {
      color: $dark-gray;
      font-family: $primary-font;
      font-size: 24px;
      margin-top: 12px;

      span {
        color: $dark-gray;
        margin-bottom: 0;
        font-family: $secondary-font;
      }
    }

    code.order-number {
      font-family: $primary-font;
      font-weight: bold;
      color: $dark-gray;
    }
  }
}

div.message {
  border-radius: 4px;
}

.content-detail-page.membership {
  .redeem-membership-route,
  .buy-membership {
    .section-header {
      margin-bottom: 16px;
    }
  }
}

.reschedule-experience {
  .session .badge {
    width: 15px;
    height: 15px;
    font-size: 12px;
  }
}

.selected-date-time,
.selectable-button {
  border-radius: 4px;
  font-size: 16px;
  color: $dark-gray;
}

.select-available-session,
.date-selector {
  .selectable-button {
    background-color: $input-background-color;
    border: $input-border;
    transition: 0.2s ease;

    &.available {
      @include hover {
        border-color: #888;
      }
    }
  }
}
