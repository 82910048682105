@import "@/styles/globals.scss";
@import '../../styles/defaults/css-properties';

// Variables
$red: #d6001c;
$dark-gray: #333f48;
$dark-red: #a30015;

$button-red: #c52929;

$very-light-gray: #e5e7e8;

$body-color: $dark-gray;
$heading-color: black;

$shadow: 0 13px 20px rgba(0, 0, 0, 0.06);

$link-hover-color: $dark-red;

$divider: 1px solid $very-light-gray;
$card-divider: $divider;
$cart-price-background-color: $very-light-gray;

$input-background-color: #f5f7f7;
$input-border: 1px solid #b2c0c7;

$steppers-3-border: $input-border;
$steppers-3-button-background-color: #f4f7f7;
$steppers-3-button-background-hover-color: $steppers-3-button-background-color;
$steppers-3-border-hover-color: #888;
$steppers-3-count-background-active-color: white;

$selectable-height: 48px;

$members-menu-icon-color: $primary-color;

$edit-order-session-border: solid 2px $very-light-gray;
$edit-order-ticket-background-color: #f5f5f5;

$datepicker-icon-color-active: white;
$datepicker-icon-color-active-hover: white;

// Defaults
@import '../../styles/defaults/index';
@import '../../styles/helpers/index';

// Placeholders
%button {
  transition: 0.2s ease;
}

%primary-button {
  background-color: $button-red;
  color: white;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  padding: 17px 30px;
}

%primary-button-hover {
  background-color: $dark-red;
  color: white;
}

%secondary-button {
  border-radius: 4px;
  background-color: $input-background-color;
  border: $input-border;
  font-size: 16px;
  color: $dark-gray;
  padding: 16px 22px;
}

%secondary-button-hover {
  border-color: #888;
  background-color: $input-background-color;
  color: $dark-gray;
}

%secondary-button-active {
  background-color: $dark-gray;
  border-color: $dark-gray;
  color: white;
}

%h1 {
  margin-bottom: 12px;
  font-family: $secondary-font;
}

%h2 {
  font-size: 24px;
  margin-bottom: 16px;
}

%subtitle {
  font-size: 12px;
  text-transform: uppercase;
  line-height: 15px;
  font-weight: 700;
  color: $red;
  margin-bottom: 8px;
}

%back-button {
  &:hover {
    color: $dark-red;
  }
}

%input {
  border: $divider;
  background-color: $input-background-color;
  border-radius: 5px;
}

%label {
  font-family: $primary-font;
  font-weight: normal;
  margin-bottom: 6px;
}

// Base elements
@import 'page';
@import 'elements';
@import 'links-and-buttons';
@import 'form-inputs';
@import 'modals';
@import 'messages';
@import 'badges';

// Components
@import '_header';
@import '_footer';
@import 'filters';
@import 'actions';
@import 'cart';
@import 'selection';
@import 'steppers-3';
@import 'calendar';
@import 'identity-form';
@import 'members-menu';
@import 'legend';

// Routes
@import 'checkout';
@import 'completed';
@import 'event-list';
@import 'buy-membership';
@import 'gift-of-membership';
@import 'forgot-membership';
@import 'event';
@import 'resend-tickets';
@import 'manage-order';
@import 'home';
@import 'my-account';

// Theme
@import 'sass/styles';
@import 'sass/header';
@import 'sass/footer';
